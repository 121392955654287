import React,{ useState } from 'react'
import { NavLink } from "react-router-dom";
import {FaChevronDown} from 'react-icons/fa'
import { useAppContext } from '../../context/appContext'

const SidebarItem = ({item}) => {
    const [open, setOpen] = useState(false)
    const{toggleSidebar} = useAppContext()
    if(item.submenu){
        return (
            <div className={open ? "sidebar-item open" : "sidebar-item"}>
                <div onClick={() => setOpen(!open)}> 
                    <span className='nav-link'>
                    <span className='icon'>{item.icon}</span>  
                    {item.text}
                    <FaChevronDown className={open ? 'toggle-btn-up' : 'toggle-btn-down'}/>
                    </span>
                </div>

                <div className="sidebar-content">
                    { item.submenu.map((child, index) => <SidebarItem key={index} item={child} />) }
                </div>

            </div>
        )
    }else{
        return (
            <NavLink to={item.path} key={item.id}  className = {({isActive}) => isActive?'nav-link active':'nav-link'} onClick={toggleSidebar}>
            <span className='icon'>{item.icon}</span>{item.text}</NavLink>
        )
    }
}

export default SidebarItem
