import adminlinks from '../utils/adminlinks'
import SidebarCNTRL from './NewSideBar/SidebarItemCNTRL'

const NavLinks = () => {

  return (
    <div className='nav-links'>
       {adminlinks.map((row,index) => { return <SidebarCNTRL key={index} row={row}/>})}
    </div>
  )
}

export default NavLinks