import React,{useEffect} from 'react'
import {VerticalTimeline,VerticalTimelineElement} from "react-vertical-timeline-component";
import Wrapper from '../../assets/wrappers/About'
import {Navbar,BigSidebar,SmallSidebar} from '../../components'
import { FaGraduationCap } from "react-icons/fa";
import { useAppContext } from '../../context/appContext'


const checkNum = (i) => {
  if(i > 3) { return i % 4 }
  else { return i }
}

function suffix_of(i) {
  var j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "ST";
  }
  if (j === 2 && k !== 12) {
      return i + "ND";
  }
  if (j === 3 && k !== 13) {
      return i + "RD";
  }
  return i + "TH";
}


const colors = ["#001CCE","#4B088A","#008080","#FE2EF7"];

const Past = () => {
  const {exPresidents,totalExPresident,getPres}= useAppContext()

  useEffect(()=>{
    getPres()
  },[totalExPresident])
  
  //console.log('EX',exPresidents,totalExPresident)
  

  return (
      <Wrapper>
      <main className='dashboard'>
            <SmallSidebar/>
            <BigSidebar/>
            <div>
              <Navbar top ='Past Presidents'/>
              <div>

                <h3 className='htop'>DELSSAA DIASPORA HISTORY</h3>
        <p className='pmid'>Delssa diaspora was founded in 2020 and these are her former presidents</p>  

        <div className= "body-section">
          <div className="timeline-section">

          <VerticalTimeline lineColor="#FF1042">

          {totalExPresident > 0 && exPresidents.map((item, index) => (

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: colors[checkNum(index)], color: "#fff" }}
              contentArrowStyle={{ borderRight: "7px solid  #FFBB24" }}
    
              iconStyle={{ background: colors[checkNum(index)], color: "#fff" }}
              icon={<FaGraduationCap />}
              key={index}
              >

              <h3 className="vertical-timeline-element-title namefield">{item.name}</h3>
              <h5 className="vertical-timeline-element-subtitle" style={{color:"yellow"}}>
                THE {suffix_of(index+1)} DELSSAA DIASPORA PRESIDENT</h5>

              <img className='imagebody' src={item.url} alt=''/>
                </VerticalTimelineElement>
              ))}
              </VerticalTimeline>

          </div>
        </div>
        

          </div>
        </div>
      </main>
      </Wrapper>
  )
}

export default Past