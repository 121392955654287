import styled from 'styled-components'

const Wrapper = styled.section`
 .body-section{
    margin-top: 20px;
}

.imagebody{
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 992px){
.vertical-timeline--two-columns .vertical-timeline-element-content {
  width:80%;
  }
}


`
export default Wrapper
