import { FormRow, FormRowSelect } from '.'
import { useAppContext } from '../context/appContext'
import Wrapper from '../assets/wrappers/SearchContainer'
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { Select, MenuItem } from "@mui/material";

const SearchContainer = () => {
  const {isLoading,search,handleChange,clearFilters,sort,sortOptions,searchCountry,changePage,classd,houseSearch} = useAppContext()
  
  countries.registerLocale(enLocale);
  const countryObj = countries.getNames("en", { select: "official" });
  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key
    };
  });
  countryArr.push({label: 'ALL', value: 'ALL'})

  const currentYear = new Date().getFullYear() 
  const houseOptions = ['ALL','Hope','Faith','Peace','Charity']
  const classOptions = ['ALL']
  for(var i = 1999; i <= currentYear; i ++) { classOptions.push(i) }
  
  const handleSearch = (e) => {
    if (isLoading) return
    changePage(1)
    //if(e.target.name === 'searchCountry' && e.target.value !== 'ALL' && page > 1 ) {changePage(1)}
    if(e.target.name === 'class'){handleChange({ name: e.target.name+'d', value: e.target.value })}
    else if(e.target.name === 'house'){handleChange({ name: e.target.name+'Search', value: e.target.value })}
    else{handleChange({ name: e.target.name, value: e.target.value })}
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    clearFilters()
  }

  return (
    <Wrapper>
      <div className='dashboard-page'>
      <form className='form' style={{marginTop:'0'}}>
        <h4 style={{fontWeight:'bold'}}>search form</h4>
        <div className='form-center'>
          {/* search position */}

          <FormRow type='text' name='search' value={search}  handleChange={handleSearch}/>
          <FormRowSelect name='sort' value={sort}  handleChange={handleSearch}  list={sortOptions}/>
          <FormRowSelect name='class' value={classd}  handleChange={handleSearch}  list={classOptions}/>
          <FormRowSelect name='house' value={houseSearch}  handleChange={handleSearch}  list={houseOptions}/>

          <div className='form-row'>
        <label htmlFor="ann-month" className='form-label'>Country</label>
        <Select className='form-input form-prop' name='searchCountry' value={searchCountry} onChange={handleSearch}>
        {!!countryArr?.length &&
          countryArr.map(({ label, value }) => (
            <MenuItem key={value} value={value}>{label}</MenuItem>
          ))}
      </Select>
      </div>
          
          <button className='btn btn-block btn-danger' disabled={isLoading} style={{marginTop:'20px'}}
          onClick={handleSubmit}>clear filters</button>
          
        </div>
      </form>
      </div>
    </Wrapper>
  )
}

export default SearchContainer
