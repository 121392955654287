import React,{useEffect} from 'react'
import Wrapper from '../assets/wrappers/LandingPage'
import { Link } from 'react-router-dom'
import groupArt from '../assets/svg/group2.svg'
import delssa from '../assets/jpeg/DELSSAA_Logo.jpeg'
import { useAppContext } from '../context/appContext'

const Landing = () => {
  const {wakeUp} = useAppContext()
  useEffect(() => {
    wakeUp()
  }, [])

    return (
        <Wrapper>       

          <div className='head-head'>
          <img src={delssa} alt='group selfie'  className='head-img'/>
            </div>   

          <div className='typewriter'>
          <h5 className='pintro'>The idea of the Alumni Association was conceived when it was considered necessary to create an organization that would embrace 
              different generations of the School. The body was expected to play a dominant role in the life of the School 
              by reflecting the views of its graduates.
              </h5>
          </div>

          <div className='container page'>
            <div className='info'>
              <div className='center'>
              <h1>
                DELSSAA <span>DIASPORA</span>
              </h1>
              </div>
              
              <Link to='/register' className='btn btn-hero'>Login/Register</Link>
            </div>
            <img src={groupArt} alt='group selfie' className='img main-img' />
          </div>
          
          <div className='footbox'>
            <footer>© EE 2022</footer>
          </div>

        </Wrapper>
      )
    }
    

    

export default Landing
