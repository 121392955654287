import React from 'react'
import Wrapper from '../assets/wrappers/house'


const Country = ({member}) => {
  return (
    <Wrapper>
    <ul className="flex-container">
        {member.map((item, index) => {
            return <div className='prof-container' key={index}>
            <div className='box'>
                <img className='img-box' src={ item.image } alt="profile-img"/>
                <h4 className='name'>{ item.fname } { item.lname }</h4>
                <h5 className='des'>{ item.location }</h5>
            </div>
            </div>
        })}
    </ul>    
    </Wrapper>
  )
}

export default Country