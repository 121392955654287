import styled from 'styled-components'

const Wrapper = styled.main`
.head-head{
    display: flex;
    justify-content: center;
    align-items: center;  
    margin-bottom: 20px;
}
.head-img{
  //height: auto;
  max-width: 15%;
  border-radius: 10%; 
  height: auto;
  margin-bottom: 20px;
  margin-top: 10px;
}

.typewriter h5 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  //border-right: .15em solid orange; /* The typwriter cursor */
  //white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
//@keyframes blink-caret {
//  from, to { border-color: transparent }
//  50% { border-color: orange; }
//}

.pintro{
  color: var(--grey-600);
    }

  nav {
    width: var(--fluid-width);
    max-width: var(--max-width);
    margin: 0 auto;
    height: var(--nav-height);
    display: flex;
    align-items: center;
  }

  .page {
    //min-height: calc(100vh - var(--nav-height));
    display: grid;
    align-items: center;
    margin-top: 20px;
  }
  h1 {
    font-weight: 700;
    span {
      color: var(--primary-500);
    }
  }
  p {
    color: var(--grey-600);
  }
  .main-img {
    display: none;
  }
  
  .intro{
    display    : flex;
    align-items: center;
  }

  .footbox{
    display: flex;
    justify-content: left;
    //align-items: center;
    margin-top: 20px;
  }

  @media (min-width: 992px) {
    .page {
      grid-template-columns: 1fr 1fr;
      column-gap: 3rem;
      
    }
    .typewriter h5 {
      max-width: 70%;
    }
    .main-img {
      display: block;
    }
    footer {
       margin-left:20px;
       position : absolute;
       bottom: 0;
    }
  }
  @media (max-width: 992px) {
    .head-img{
        max-width: 50%;
    }
    .pintro{
      font-size: large;
    }
    .center{
      margin-top: 20px;
    }
    
  }
`
export default Wrapper
