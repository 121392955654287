import styled from 'styled-components'

const Wrapper = styled.section`

.flex-container {
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.flex-item {
  border: 2px solid #02899C;
  padding: 1em;
  width: 200px;
  height: 150px;
  margin: 20px;
  text-align: center;
}

.box {
    border-radius: 5px;
    color:white;
    padding: 0px;
    height: 190px;
    width: 250px;
    text-align: center;
    transition: 0.3s;
}


.img-box {
    height: 190px;
    width: 190px;
    top:-80px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: 0 3px 5px 2px rgba(255, 142, 164, 0.3);
    position: relative;
    background-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}

.prof-container {
    margin: 10px;
    width: fit-content;
    padding-top: 80px;
}
.name{
    margin-top: -70px;
    font-size: medium;
    text-transform: uppercase;
}
.des {
    margin-top: -20px;
    font-size: medium;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
}
`
export default Wrapper
