import styled from 'styled-components'

const Wrapper = styled.section`

.sidebar{
  height: 100%;
  overflow: auto;
}


.toggle-btn-down{
  margin-left: auto;
  cursor: pointer;
  transition: transform .3s;
}

.toggle-btn-up{
  margin-left: auto;
  cursor: pointer;
  transition: transform .3s;
  transform: rotate(180deg);
}

.sidebar-item{
  //padding: .75em 1em;
  cursor: pointer;
  display: block;
  transition: background-color .15s;
  border-radius: 5px;
}

.sidebar-content{
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content{
  height: auto;
}

`
export default Wrapper
