import {FaNewspaper} from 'react-icons/fa'
import {FaInfo} from 'react-icons/fa'
import {FaWpforms} from 'react-icons/fa'
import {ImProfile} from 'react-icons/im'
import { FaHome } from 'react-icons/fa'
import { ImNewspaper } from 'react-icons/im'
import { ImFilePicture } from 'react-icons/im'
import {IoIosPeople} from 'react-icons/io'
import {FaEdit} from 'react-icons/fa'
import {RiRefund2Line} from 'react-icons/ri'
import {GrProjects} from 'react-icons/gr'
import {RiGovernmentLine,RiGovernmentFill} from 'react-icons/ri'
import {ImInfo} from 'react-icons/im'


    const adminlinks = [
        {id:1,text:'News',path:'/news',icon:<FaNewspaper/>},
        {   id:2,
            text:'Leadership',
            path:'#',
            submenu: [
                {
                  text: 'Executive Committee',
                  path: '/exco',
                  icon: <RiGovernmentLine/>
                },
                {
                    text: 'Past Presidents',
                    path: '/past',
                    icon: <RiGovernmentFill/>
                  }],
            icon:<FaInfo/>
        },
        {id:3,text:'About',path:'/about',icon:<ImInfo/>},
        {id:4,text:'Edit profile',path:'/profile',icon:<ImProfile/>},
        {id:5,text:'Home',path:'/user',icon:<FaHome/>},
        {id:6,text:'Gallery',path:'/gallery',icon:<ImFilePicture/>},
        {id:7,text:'Members',path:'/members',icon:<IoIosPeople/>},
        {id:8,text:'Chapter Projects',path:'/project',icon:<RiRefund2Line/>},
        {id:9,text:'Resources',path:'/resources',icon:<FaWpforms/>},
        {id:10,text:'contact us',path:'/contact',icon:<FaWpforms/>},
        {id:11,text:'Add news Item/Event',admin:true,path:'/addnewsitem',icon:<ImNewspaper/>},
        {id:12,text:'Add Image',admin:true,path:'/addimage',icon:<ImFilePicture/>},
        {id:13,text:'Add User/Admin',admin:true,path:'/adduser',icon:<FaEdit/>},
        {id:14,text:'Add/Update Project',admin:true,path:'/addproject',icon:<GrProjects/>},
        {id:15,text:'Delete News/Event',admin:true,path:'/deletenews',icon:<ImNewspaper/>},
        {id:16,text:'Add Executive Member',admin:true,path:'/addexec',icon:<ImFilePicture/>},
    ]
        
export default adminlinks
