export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'

export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN'
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS'
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR'

export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR'
export const LOGOUT_USER = 'LOGOUT_USER'

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN'
export const UPDATE_USER_SUCCESS ='UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const UPDATE_USER_IMAGE_BEGIN = 'UPDATE_USER_IMAGE_BEGIN'
export const UPDATE_USER_IMAGE_SUCCESS ='UPDATE_USER_IMAGE_SUCCESS'
export const UPDATE_USER_IMAGE_ERROR = 'UPDATE_USER_IMAGE_ERROR'

export const HANDLE_CHANGE = 'HANDLE_CHANGE'
export const CLEAR_VALUES = 'CLEAR_VALUES'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const CHANGE_PAGE = 'CHANGE_PAGE'

export const CREATE_JOB_BEGIN = 'CREATE_JOB_BEGIN'
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS'
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR'

export const DISPLAY_CUSTOM_ALERT = 'DISPLAY_CUSTOM_ALERT'

export const GET_JOBS_BEGIN = 'GET_JOBS_BEGIN'
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS'

export const SET_EDIT_JOB = 'SET_EDIT_JOB'

export const DELETE_JOB_BEGIN = 'DELETE_JOB_BEGIN'

export const EDIT_JOB_BEGIN = 'EDIT_JOB_BEGIN'
export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS'
export const EDIT_JOB_ERROR = 'EDIT_JOB_ERROR'

export const GET_IMAGES_BEGIN='GET_IMAGES_BEGIN'
export const GET_IMAGES_SUCCESS='GET_IMAGES_SUCCESS'
export const GET_IMAGES_ERROR='GET_IMAGE_ERROR'

export const SEND_EMAIL_BEGIN='SEND_EMAIL_BEGIN'
export const SEND_EMAIL_SUCCESS='SEND_EMAIL_SUCCESS'
export const SEND_EMAIL_ERROR='SEND_EMAIL_ERROR'

export const ADD_NEW_IMAGE_BEGIN = 'ADD_NEW_IMAGE_BEGIN'
export const ADD_NEW_IMAGE_SUCCESS = 'ADD_NEW_IMAGE_SUCCESS'
export const ADD_NEW_IMAGE_ERROR = 'ADD_NEW_IMAGE_ERROR'

export const ADD_NEWS_ITEM_BEGIN = 'ADD_NEWS_ITEM_BEGIN'
export const ADD_NEWS_ITEM_SUCCESS = 'ADD_NEWS_ITEM_SUCCESS'
export const ADD_NEWS_ITEM_ERROR = 'ADD_NEWS_ITEM_ERROR'

export const GET_NEWS_ITEM_BEGIN = 'GET_NEWS_ITEM_BEGIN'
export const GET_NEWS_ITEM_SUCCESS = 'GET_NEWS_ITEM_SUCCESS'
export const GET_NEWS_ITEM_ERROR = 'GET_NEWS_ITEM_ERROR'

export const GET_MEMBERS_BEGIN = 'GET_MEMBERS_BEGIN'
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS'
export const GET_MEMBERS_ERROR = 'GET_MEMBERS_ERROR'

export const ADD_NEW_USER_TO_REGISTER_BEGIN = 'ADD_NEW_USER_TO_REGISTER_BEGIN'
export const ADD_NEW_USER_TO_REGISTER_SUCCESS = 'ADD_NEW_USER_TO_REGISTER_SUCCESS'
export const ADD_NEW_USER_TO_REGISTER_ERROR = 'ADD_NEW_USER_TO_REGISTER_ERROR'

export const MAKE_ADMIN_BEGIN = 'MAKE_ADMIN_BEGIN'
export const MAKE_ADMIN_SUCCESS = 'MAKE_ADMIN_SUCCESS'
export const MAKE_ADMIN_ERROR = 'MAKE_ADMIN_ERROR'

export const DELETE_NEWS_ITEM_BEGIN ='DELETE_NEWS_ITEM_BEGIN'
export const DELETE_NEWS_ITEM_SUCCESS ='DELETE_NEWS_ITEM_SUCCESS'
export const DELETE_NEWS_ITEM_ERROR ='DELETE_NEWS_ITEM_ERROR'

export const GET_BDAY_SUCCESS = 'GET_BDAY_SUCCESS'
export const UPDATE_GLOBAL_COUNT ='UPDATE_GLOBAL_COUNT'
export const CHANGE_IMAGE_PAGE = 'CHANGE_IMAGE_PAGE'
export const CREATE_COMMENT_BEGIN ='CREATE_COMMENT_BEGIN'
export const CREATE_COMMENT_SUCCESS ='CREATE_COMMENT_SUCCESS'
export const CREATE_COMMENT_ERROR ='CREATE_COMMENT_ERROR'
export const GET_COMMENT_BEGIN ='GET_COMMENT_BEGIN'
export const GET_COMMENT_SUCCESS ='GET_COMMENT_SUCCESS'
export const GET_COMMENT_ERROR ='GET_COMMENT_ERROR'
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS'
export const UPDATE_COMMENT_BEGIN = 'UPDATE_COMMENT_BEGIN'
export const UPDATE_COMMENT_ERROR = 'UPDATE_COMMENT_ERROR'
export const DELETE_COMMENT_BEGIN ='DELETE_COMMENT_BEGIN'
export const DELETE_COMMENT_ERROR ='DELETE_COMMENT_ERROR'
export const DELETE_COMMENT_SUCCESS ='DELETE_COMMENT_SUCCESS'
export const ADD_NEW_EXCO_BEGIN = 'ADD_NEW_EXCO_BEGIN'
export const ADD_NEW_EXCO_ERROR = 'ADD_NEW_EXCO_ERROR'
export const ADD_NEW_EXCO_SUCCESS = 'ADD_NEW_EXCO_SUCCESS'
export const GET_EXCO_BEGIN ='GET_EXCO_BEGIN'
export const GET_EXCO_SUCCESS ='GET_EXCO_SUCCESS'
export const GET_EXCO_ERROR ='GET_EXCO_ERROR'

export const ADD_PROJECT_BEGIN ='ADD_PROJECT_BEGIN'
export const ADD_PROJECT_SUCCESS ='ADD_PROJECT_SUCCESS'
export const ADD_PROJECT_ERROR ='ADD_PROJECT_ERROR'
export const GET_PROJECT_BEGIN ='GET_PROJECT_BEGIN'
export const GET_PROJECT_SUCCESS ='GET_PROJECT_SUCCESS'
export const GET_PROJECT_ERROR ='GET_PROJECT_ERROR'

export const EDIT_PROJECT_BEGIN ='EDIT_PROJECT_BEGIN'
export const EDIT_PROJECT_ERROR ='EDIT_PROJECT_ERROR'
export const EDIT_PROJECT_SUCCESS ='EDIT_PROJECT_SUCCESS'
export const DELETE_PROJECT_BEGIN ='DELETE_PROJECT_BEGIN'
export const DELETE_PROJECT_ERROR ='DELETE_PROJECT_ERROR'
export const DELETE_PROJECT_SUCCESS ='DELETE_PROJECT_SUCCESS'

export const ADD_EVENT_BEGIN ='ADD_EVENT_BEGIN'
export const ADD_EVENT_ERROR ='ADD_EVENT_ERROR'
export const ADD_EVENT_SUCCESS ='ADD_EVENT_SUCCESS'
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS'
export const DELETE_EVENT_BEGIN ='DELETE_EVENT_BEGIN'
export const DELETE_EVENT_ERROR ='DELETE_EVENT_ERROR'
export const DELETE_EVENT_SUCCESS ='DELETE_EVENT_SUCCESS'

export const SEND_CODE_BEGIN ='SEND_CODE_BEGIN'
export const SEND_CODE_ERROR ='SEND_CODE_ERROR'
export const SEND_CODE_SUCCESS ='SEND_CODE_SUCCESS'

export const VAL_PIN_BEGIN ='VAL_PIN_BEGIN'
export const VAL_PIN_ERROR ='VAL_PIN_ERROR'
export const VAL_PIN_SUCCESS ='VAL_PIN_SUCCESS'

export const READ_UPDATE_SUCCESS = 'READ_UPDATE_SUCCESS'
export const GET_ANN_SUCCESS = 'GET_ANN_SUCCESS'

export const ADD_NEW_PRES_BEGIN ='ADD_NEW_PRES_BEGIN'
export const ADD_NEW_PRES_ERROR ='ADD_NEW_PRES_ERROR'
export const ADD_NEW_PRES_SUCCESS ='ADD_NEW_PRES_SUCCESS'

export const GET_PRES_BEGIN ='GET_PRES_BEGIN'
export const GET_PRES_SUCCESS ='GET_PRES_SUCCESS'

export const GET_HOUSE_MEM_SUCCESS = 'GET_HOUSE_MEM_SUCCESS'

export const GET_COUNTRY_MEM_SUCCESS = 'GET_COUNTRY_MEM_SUCCESS'


