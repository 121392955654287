import React from 'react'
import Wrapper from '../../assets/wrappers/NewSideBar'
import SidebarItem from './SidebarItem'
import { useAppContext } from '../../context/appContext'       

const SidebarCNTRL = ({row}) => {
    const{user} = useAppContext()
    return (
        <Wrapper>
        <div>
        {!row.admin && !row.reg && <SidebarItem item={row} />}
        {row.reg && !user.admin && <SidebarItem item={row} />}
        {row.admin && user.admin && <SidebarItem item={row} />}
        </div>
        </Wrapper>
    )
}

export default SidebarCNTRL